import employeeAPI from '../../services/EmployeeService';
import { useCallback, useMemo } from 'react';
import { IEmployee } from '../../models/IEmployee';

const useEmployeeOptions = (trashed?: 'true' | 'false') => {
    const { data, isLoading } = employeeAPI.useFetchAllEmployeesQuery({ trashed });

    const employees = useMemo<IEmployee[]>(() => data?.data || [], [data]);

    const getEmployeeById = useCallback((id: number) => employees.find((employee) => employee.id === id), [employees]);

    return { employees, isEmployeesLoading: isLoading, getEmployeeById };
};

export default useEmployeeOptions;
