import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { axiosBaseQuery } from '../utils/axios';
import {
    DashboardAppointment,
    ICustomer,
    ICustomerDashboard,
    ICustomerLoginData,
    ICustomerNote,
    ICustomerNoteCreatePayload,
    ICustomerNoteUpdatePayload,
    ICustomerOtpData,
    ICustomerPayload,
    ICustomerUser
} from 'models/ICustomer';
import { IBaseParams, IPaginateResponse } from 'models/IPaginateResponse';
import { CustomerCoupon } from '../models/ICoupon';

const customerAPI = createApi({
    reducerPath: 'customerAPI',
    tagTypes: ['Customer', 'CustomerNotes', 'CustomerDashboard', 'CustomerDashboardCoupons'],
    baseQuery: axiosBaseQuery(),
    endpoints: (build) => ({
        fetchAllCustomers: build.query<IPaginateResponse<ICustomer[]>, IBaseParams>({
            query: ({ per_page = null, page = 1, search = null, sort = null, order = null, trashed = null }) => ({
                url: `/customers`,
                method: 'GET',
                params: {
                    per_page,
                    page,
                    search,
                    sort,
                    order,
                    trashed
                }
            }),
            providesTags: (result) => ['Customer']
        }),
        getCustomer: build.query<ICustomer, string>({
            query: (id) => ({ url: `/customers/${id}`, method: 'GET' }),
            providesTags: ['Customer']
        }),
        createCustomer: build.mutation<ICustomer, ICustomerPayload>({
            query: (customer) => ({
                url: `/customers`,
                method: 'POST',
                data: customer
            }),
            invalidatesTags: ['Customer']
        }),
        updateCustomer: build.mutation<ICustomer, ICustomerPayload & { id: number }>({
            query: (customer) => ({
                url: `/customers/${customer.id}`,
                method: 'PUT',
                data: customer
            }),
            invalidatesTags: ['Customer']
        }),
        deleteCustomer: build.mutation<ICustomer, ICustomer>({
            query: (customer) => ({
                url: `/customers/${customer.id}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['Customer']
        }),

        // Notes
        getCustomerNotes: build.query<IPaginateResponse<ICustomerNote[]>, number | string>({
            query: (customerId) => ({
                url: `/customers/${customerId}/notes`,
                method: 'GET',
                params: {
                    per_page: -1
                }
            }),
            providesTags: ['CustomerNotes']
        }),

        getCustomerNoteById: build.query<ICustomerNote, { customerId: number | string; noteId: number | string }>({
            query: ({ customerId, noteId }) => ({
                url: `/customers/${customerId}/notes/${noteId}`,
                method: 'GET'
            }),
            providesTags: ['CustomerNotes']
        }),

        createCustomerNote: build.mutation<
            ICustomerNote,
            {
                customerId: string | number;
                data: ICustomerNoteCreatePayload;
            }
        >({
            query: ({ customerId, data }) => ({
                url: `/customers/${customerId}/notes`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['CustomerNotes']
        }),

        updateCustomerNote: build.mutation<
            ICustomerNote,
            {
                customerId: string | number;
                data: ICustomerNoteUpdatePayload;
            }
        >({
            query: ({ customerId, data: { id, ...rest } }) => ({
                url: `/customers/${customerId}/notes/${id}`,
                method: 'PUT',
                data: rest
            }),
            invalidatesTags: ['CustomerNotes']
        }),

        deleteCustomerNote: build.mutation<unknown, { customerId: number | string; noteId: number | string }>({
            query: ({ customerId, noteId }) => ({
                url: `/customers/${customerId}/notes/${noteId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['CustomerNotes']
        }),

        customerLogin: build.mutation<
            { token: string },
            ICustomerLoginData & {
                companySlug: string;
            }
        >({
            query: ({ companySlug, ...data }) => ({
                url: `/public/company/${companySlug}/customer-account/login`,
                method: 'POST',
                data: { ...data, remember: null }
            })
        }),

        customerOtp: build.mutation<ICustomerUser, ICustomerOtpData & { companySlug: string }>({
            query: ({ companySlug, ...data }) => ({
                url: `/public/company/${companySlug}/customer-account/otp`,
                method: 'POST',
                data
            })
        }),

        customerLogout: build.mutation<unknown, string>({
            query: (companySlug) => ({
                url: `/public/company/${companySlug}/customer-account/logout`,
                method: 'POST'
            })
        }),

        getCustomerDashboard: build.query<ICustomerDashboard, string>({
            query: (companySlug) => ({
                url: `/public/company/${companySlug}/customer-account/dashboard`,
                method: 'GET'
            }),
            providesTags: ['CustomerDashboard']
        }),

        getCustomerDashboardCoupons: build.query<
            IPaginateResponse<CustomerCoupon[]>,
            {
                companySlug: string;
                page: number;
                per_page: number;
            }
        >({
            query: ({ companySlug, page, per_page }) => ({
                url: `/public/company/${companySlug}/customer-account/coupons`,
                method: 'GET',
                params: {
                    page,
                    per_page,
                    paginator: 'paginate'
                }
            }),
            providesTags: ['CustomerDashboardCoupons']
        }),

        getCustomerDashboardAppointments: build.query<
            IPaginateResponse<DashboardAppointment[]>,
            {
                companySlug: string;
                page: number;
                per_page: number;
            }
        >({
            query: ({ companySlug, page, per_page }) => ({
                url: `/public/company/${companySlug}/customer-account/appointments`,
                method: 'GET',
                params: {
                    page,
                    per_page,
                    sort: 'start_at',
                    order: 'desc',
                    paginator: 'paginate'
                }
            })
        }),

        createCustomerCoupon: build.mutation<unknown, { companySlug: string; amount: number }>({
            query: ({ companySlug, ...data }) => ({
                url: `/public/company/${companySlug}/customer-account/coupons`,
                method: 'POST',
                data
            }),
            invalidatesTags: ['CustomerDashboardCoupons']
        }),

        deleteCustomerCoupon: build.mutation<unknown, { companySlug: string; couponId: number }>({
            query: ({ companySlug, couponId }) => ({
                url: `/public/company/${companySlug}/customer-account/coupons/${couponId}`,
                method: 'DELETE'
            }),
            invalidatesTags: ['CustomerDashboardCoupons']
        })
    })
});

export default customerAPI;
