import { AppointmentHistoryEvent, IAppointmentHistory } from '../../models/IAppointment';
import { Stack, useTheme } from '@mui/material';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import SettingsIcon from '@mui/icons-material/Settings';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { FC, Fragment, useCallback } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EventIcon from '@mui/icons-material/Event';
import moment from 'moment';
import AppointmentHistoryItem from './components/AppointmentHistoryItem';

type AppointmentHistoryProps = {
    data: IAppointmentHistory[];
    isMobile?: boolean;
};

const AppointmentHistory: FC<AppointmentHistoryProps> = ({ data, isMobile }) => {
    const theme = useTheme();

    const eventContent = {
        end_at: { icon: <AccessTimeFilledIcon />, text: 'End time changed', color: '#05843B' },
        start_at: { icon: <AccessTimeFilledIcon />, text: 'Start time changed', color: '#05843B' },
        entry_duration: { icon: <AccessTimeFilledIcon />, text: 'Duration changed', color: '#05843B' },
        service_id: { icon: <SettingsSuggestIcon />, text: 'Service changed', color: theme.palette.grey[800] },
        price: { icon: <LocalAtmIcon />, text: 'Price changed', color: '#F86F60' },
        employee_id: { icon: <AccountCircleIcon />, text: 'Provider changed', color: '#3483FA' },
        customer_id: { icon: <AccountCircleIcon />, text: 'Customer changed', color: '#3483FA' },

        note: { icon: <DescriptionIcon />, text: 'Note changed', color: '#708B9E' },
        private_note: {
            icon: <DescriptionIcon />,
            text: 'Private note changed',
            color: '#708B9E'
        },
        status: { icon: <SettingsIcon />, text: 'Status changed', color: '#0A3A5B' },
        payments: { icon: <LocalAtmIcon />, text: 'Payment was changed', color: '#F86F60' },
        is_notifications_enabled: { icon: <NotificationsIcon />, text: 'Notification status changed', color: '#9B59B6' }
    };

    const convertMessageData = useCallback(
        (item: IAppointmentHistory) => {
            let color = '#E67E22';
            let icon = item.event_source === 'widget' ? <OpenInNewIcon /> : <EventIcon />;
            let text: string | null = null;
            switch (item.event) {
                case AppointmentHistoryEvent.Created: {
                    text = 'Appointment created';
                    break;
                }

                case AppointmentHistoryEvent.ServiceCreated: {
                    text = 'Appointment service created';
                    break;
                }

                case AppointmentHistoryEvent.ServiceUpdated: {
                    text = 'Appointment service changed';
                    break;
                }

                case AppointmentHistoryEvent.ServiceDeleted: {
                    text = 'Appointment service deleted';
                    break;
                }

                case AppointmentHistoryEvent.ServiceOptionCreated: {
                    text = 'Service option created';
                    break;
                }

                case AppointmentHistoryEvent.ServiceOptionUpdated: {
                    text = 'Service option changed';
                    break;
                }

                case AppointmentHistoryEvent.ServiceOptionDeleted: {
                    text = 'Service option deleted';
                    break;
                }

                case AppointmentHistoryEvent.ProductCreated: {
                    text = 'Appointment product created';
                    break;
                }

                case AppointmentHistoryEvent.ProductUpdated: {
                    text = 'Appointment product changed';
                    break;
                }

                case AppointmentHistoryEvent.ProductDeleted: {
                    text = 'Appointment product deleted';
                    break;
                }

                case AppointmentHistoryEvent.CustomerPivotCreated: {
                    text = 'Customer added';
                    icon = eventContent.customer_id.icon;
                    color = eventContent.customer_id.color;
                    break;
                }

                case AppointmentHistoryEvent.CustomerPivotUpdated: {
                    text = 'Customer updated';
                    icon = eventContent.customer_id.icon;
                    color = eventContent.customer_id.color;
                    break;
                }
            }

            return text ? (
                <AppointmentHistoryItem
                    icon={icon}
                    text={text}
                    color={color}
                    createdAt={moment(item.created_at).format('MMM D,YYYY | hh:mm a (z)')}
                    createdBy={item.event_source === 'widget' ? 'via Widget' : `by ${item?.user?.title}`}
                    isMobile={isMobile}
                />
            ) : null;
        },
        [eventContent.customer_id.color, eventContent.customer_id.icon, isMobile]
    );

    return (
        <Stack spacing={2}>
            {data?.map((item, index) => (
                <Fragment key={`event-${index}`}>
                    {convertMessageData(item)
                        ? convertMessageData(item)
                        : Object.keys(item.modified_values).map((key) => {
                              const content = eventContent[key as keyof typeof eventContent];
                              return (
                                  <AppointmentHistoryItem
                                      key={key}
                                      color={content?.color ?? theme.palette.grey[800]}
                                      icon={content?.icon}
                                      text={content?.text ?? `${key.replaceAll('_', ' ')} changed`}
                                      createdAt={moment(item.created_at).format('MMM D, YYYY hh:mm a (z)')}
                                      createdBy={item.event_source === 'widget' ? 'via Widget' : `by ${item?.user?.title}`}
                                      isMobile={isMobile}
                                  />
                              );
                          })}
                </Fragment>
            ))}
        </Stack>
    );
};

export default AppointmentHistory;
