import { FC, useCallback, useEffect, useMemo } from 'react';
import moment, { Moment } from 'moment-timezone';
import useLocationOptions from '../../hooks/options/useLocationOptions';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { setCalendarLocationFilter } from '../../store/slices/calendarFilterSlice';
import HeaderSelect from '../../ui-component/header-select';
import { InputAdornment } from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';

const LocationSwitcher: FC<{ fullWidth?: boolean }> = ({ fullWidth }) => {
    const dispatch = useAppDispatch();
    const { selectedLocation } = useAppSelector((state) => state.calendarFilter);

    const { locations } = useLocationOptions();

    const options = useMemo(
        () =>
            locations.map((location) => {
                const tz: Moment = moment.tz(location.time_zone);
                const offset = tz.format('Z');
                const tz_abbr = tz.format('z');
                const tzLabel = tz_abbr === 'UTC' ? '(UTC)' : `(${tz_abbr}/ UTC${offset})`;
                return {
                    id: location.id,
                    label: location.name,
                    secondaryText: tzLabel
                };
            }),
        [locations]
    );

    const handleChange = useCallback(
        (id: number) => {
            const loc = locations.find((location) => location.id === id);
            if (loc) {
                dispatch(setCalendarLocationFilter(loc));
            }
        },
        [locations, dispatch]
    );

    useEffect(() => {
        if (!selectedLocation && locations.length) {
            dispatch(setCalendarLocationFilter(locations[0]));
        }
    }, [dispatch, selectedLocation, locations]);

    if (!selectedLocation) {
        return null;
    }

    return (
        <HeaderSelect
            label="Location"
            value={selectedLocation?.id ?? null}
            onChange={(e) => handleChange(parseInt(e.target.value, 10))}
            options={options}
            disabled={locations.length < 2}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <PlaceOutlinedIcon />
                    </InputAdornment>
                )
            }}
            fullWidth={fullWidth}
        />
    );
};

export default LocationSwitcher;
