import { useMemo, useState } from 'react';
import { IEmployee } from '../../../models/IEmployee';
import { Box, Grid, Autocomplete, TextField } from '@mui/material';
import UserAvatar from '../../UserAvatar';
import { FormFieldProps } from '../index';
import SectionHeading from '../../SectionHeading';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';

type ProviderSelectProps = Omit<FormFieldProps, 'setFieldValue' | 'setFieldTouched'> & {
    onChange: (v: IEmployee | null) => void;
    whitelist?: Array<number>;
    location_id?: number | null;
};

const ProviderSelect = ({ values, onChange, touched, errors, handleBlur, disabled, whitelist = [], location_id }: ProviderSelectProps) => {
    const [inputVal, setInputVal] = useState('');
    const provider = useMemo(() => values?.employee || null, [values]);

    const { employees } = useEmployeeOptions('true');
    const options = useMemo(
        () =>
            employees.filter(({ id, deleted_at, locations, services }) => {
                if (whitelist.includes(id)) {
                    return true;
                }
                const existsOnLocation = location_id ? locations.some((loc) => loc.id === location_id) : true;
                const hasServices = services.length > 0;

                return !deleted_at && existsOnLocation && hasServices;
            }),
        [employees, location_id, whitelist]
    );

    const label = useMemo(() => (!provider && !inputVal ? 'Select Provider' : ''), [inputVal, provider]);

    return (
        <>
            <SectionHeading mb={0.5}>Provider</SectionHeading>
            <Grid container item xs={12}>
                <Autocomplete
                    id="employee"
                    fullWidth
                    value={provider}
                    disabled={!options.length || disabled}
                    options={options || []}
                    getOptionLabel={(option: IEmployee) => option.title}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText="No items match your search"
                    inputValue={inputVal}
                    onInputChange={(_event, newInputValue) => {
                        setInputVal(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            hiddenLabel={!label}
                            InputLabelProps={{
                                shrink: false
                            }}
                            error={Boolean(touched?.employee && errors?.employee)}
                            helperText={touched?.employee ? errors?.employee : null}
                        />
                    )}
                    onChange={(_e, newValue) => {
                        onChange(newValue);
                    }}
                    onBlur={handleBlur}
                    renderOption={(props, option: IEmployee) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                            <UserAvatar employee={option} sx={{ width: '30px', height: '30px', mr: 1 }} />
                            {option.title}
                        </Box>
                    )}
                    disableClearable
                />
            </Grid>
        </>
    );
};

export default ProviderSelect;
