import CustomerInfoDialog from '../../views/customer/customer-info';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeDialog, DialogTypes } from '../../store/slices/entityDialogsSlice';
import { useCallback, useEffect, useMemo } from 'react';
import { Box, Drawer, useMediaQuery } from '@mui/material';

import {
    setAppointmentDetailsFormId,
    setIsForeignAppointment,
    setRecurringEventDate,
    setSelectedEvent as setStoreEvent,
    setSelectedRange
} from '../../store/slices/calendarSlice';
import moment from 'moment-timezone';
import { clearOuterAppointment } from '../../store/slices/outerAppointmentSlice';
import { openConfirmPopup } from '../../store/confirmPopupSlice';
import { useBlocker, useLocation } from 'react-router-dom';
import { setSelectedCustomerId } from '../../store/slices/calendarFilterSlice';
import CashLedger from '../cash-ledger';
import useEntityDialogs from '../../layout/use-entity-dialogs';
import AppointmentCard from '../appointments/appointment-card/AppointmentCard';
import NewAppointmentModal from '../appointments/new-appointment-modal';
import InviteCreationDialog from '../appointments/invite-creation-dialog';
import BlockTimeDialog from '../appointments/appointment-block/BlockTimeDialog';
import CreateRecurringEvent from '../recurring-events/CreateRecurrinEvent';
import RecurringEventDetails from '../recurring-events/RecurringEventDetails';
import UpdatedStyleWrapper from '../updated-style-wrapper';

export const SIDEBAR_WIDTH = '490px';

const EntityDialogs = () => {
    const location = useLocation();
    const { entityId, entityType, showAsNormalDrawer, open } = useEntityDialogs();
    const isMobile = useMediaQuery('(max-width:768px)');

    const { selectedLocation, selectedCustomerId } = useAppSelector((state) => state.calendarFilter);
    const { selectedRange, selectedEmployeeId, shouldSubmitFormOnTabChange, recurringEventDate } = useAppSelector(
        (state) => state.calendar
    );

    const inviteDuration = useMemo(() => {
        const calendarMinDuration = 15;
        return calendarMinDuration === selectedRange?.duration ? undefined : selectedRange?.duration;
    }, [selectedRange]);

    const newAppointmentSelectedRangeEnd = useMemo(() => {
        const calendarMinDuration = 15;
        if (selectedRange) {
            const start = moment(selectedRange.start);
            const end = moment(selectedRange.end);
            const diff = end.diff(start, 'minutes');

            return diff !== calendarMinDuration ? selectedRange.end : undefined;
        }

        return undefined;
    }, [selectedRange]);

    const dispatch = useAppDispatch();

    const handleClose = useCallback(() => {
        dispatch(closeDialog());

        // From old calendar action
        dispatch(clearOuterAppointment());
        dispatch(setIsForeignAppointment(false));
        dispatch(setSelectedRange(null));

        // Clearing appointment edit state
        dispatch(clearOuterAppointment());
        dispatch(setStoreEvent(null));
        dispatch(setRecurringEventDate(null));

        // Clear details form id
        dispatch(setAppointmentDetailsFormId(undefined));

        // Clear selected customer id
        dispatch(setSelectedCustomerId(null));
    }, [dispatch]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            currentLocation.pathname !== nextLocation.pathname && shouldSubmitFormOnTabChange && !!entityId && entityType === 'appointment'
    );

    useEffect(() => {
        if (blocker.state === 'blocked') {
            dispatch(
                openConfirmPopup({
                    text: 'Discard unsaved changes?',
                    confirmText: 'Discard',
                    onClose: () => {
                        blocker.reset();
                    },
                    onConfirm: () => {
                        blocker.proceed();
                        dispatch(closeDialog());
                    }
                })
            );
        }
    }, [blocker, dispatch]);

    useEffect(() => {
        if (entityType) {
            dispatch(closeDialog());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    return (
        <>
            <Box sx={{ width: 0, flexShrink: 0, '&:not(:empty)': { width: open ? SIDEBAR_WIDTH : 0 } }}>
                <Drawer
                    anchor="right"
                    className="entityDetailsDrawer"
                    variant={showAsNormalDrawer ? 'temporary' : 'persistent'}
                    open={open}
                    ModalProps={{
                        keepMounted: false
                    }}
                    sx={{
                        height: '100vh',
                        // @ts-ignore
                        // eslint-disable-next-line no-dupe-keys
                        height: '100svh',
                        '& .MuiDrawer-paper': {
                            top: 0,
                            width: SIDEBAR_WIDTH,
                            maxWidth: '100vw',
                            borderLeft: !showAsNormalDrawer ? '2px solid' : undefined,
                            // todo: replace with grey.300 after v4 mui instance will be removed
                            borderLeftColor: '#b4b4b4'
                        }
                    }}
                >
                    <Box
                        sx={{
                            height: '100%',
                            '& .MuiModal-root': { position: 'static' },
                            '& .MuiBackdrop-root': { display: 'none' },
                            '& .MuiDialog-paper': {
                                margin: 0,
                                borderRadius: '0 !important',
                                boxShadow: 'none',
                                width: '100%'
                            },
                            '& .MuiDialog-paper.MuiDialog-paperScrollPaper': {
                                height: '100vh'
                            }
                        }}
                    >
                        {/* Appointment Details */}
                        {entityType === 'appointment' && entityId ? (
                            <AppointmentCard cardEvent={String(entityId)} onCancel={handleClose} />
                        ) : null}

                        {/* New Appointment */}
                        {selectedLocation && entityType === 'appointment' && !entityId ? (
                            <NewAppointmentModal
                                location={selectedLocation}
                                employeeId={selectedEmployeeId}
                                onClose={handleClose}
                                startDate={selectedRange?.start}
                                endDate={!isMobile ? newAppointmentSelectedRangeEnd : undefined}
                                customerId={selectedCustomerId}
                            />
                        ) : null}

                        {/* Appointment Invite */}
                        {entityType === 'invite' && !entityId ? (
                            <InviteCreationDialog
                                onClose={handleClose}
                                duration={inviteDuration || 0}
                                employeeId={selectedEmployeeId || 0}
                                locationId={selectedLocation?.id || 0}
                            />
                        ) : null}

                        {/* Time Block */}
                        {selectedLocation && entityType === 'block_time' ? (
                            <BlockTimeDialog
                                onClose={handleClose}
                                eventId={entityId && entityType === 'block_time' ? entityId : undefined}
                                range={selectedRange}
                                location={selectedLocation}
                                selectedEmployeeId={selectedEmployeeId}
                            />
                        ) : null}

                        {/* Customer Info */}
                        {entityType === 'customer' && !!entityId && (
                            <CustomerInfoDialog customerId={String(entityId)} onClose={handleClose} />
                        )}

                        {/* Cash Ledger */}
                        {entityType === DialogTypes.Ledger && <CashLedger onClose={handleClose} />}

                        {/* New Recurring Event */}
                        {selectedLocation && entityType === DialogTypes.RecurringEvent && !entityId && (
                            <CreateRecurringEvent
                                location={selectedLocation}
                                date_range={selectedRange}
                                onClose={handleClose}
                                employeeId={selectedEmployeeId}
                            />
                        )}

                        {/* Edit Recurring Event */}
                        {entityType === DialogTypes.RecurringEvent && entityId && (
                            <RecurringEventDetails id={entityId} entry_date={recurringEventDate ?? ''} onClose={handleClose} />
                        )}
                    </Box>
                </Drawer>
            </Box>
            <UpdatedStyleWrapper>
                <Box id="permanent-drawer-contained" sx={{ position: 'fixed', zIndex: 1299 }} />
            </UpdatedStyleWrapper>
        </>
    );
};

export default EntityDialogs;
