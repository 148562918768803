import { AppointmentFormType, AppointmentStatuses, AppointmentType } from '../../../models/IAppointment';
import { useAppSelector } from '../../../hooks/redux';
import moment from 'moment/moment';
import { ILocation } from '../../../models/ILocation';
import { useMemo, useState } from 'react';
import { isPast } from 'date-fns';
import { Box, Button, CircularProgress } from '@mui/material';
import customerAPI from '../../../services/CustomerService';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAppointmentFunctions } from '../../../hooks/appointments';
import AppointmentForm from '../../appointment-form';
import useEntityDrawerSpacing from '../../entity-drawer-layout/use-entity-drawer-spacing';
import UpdatedStyleWrapper from '../../updated-style-wrapper';
import EntityDrawerContainer from '../../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';

interface NewAppointmentModalProps {
    onClose: () => void;
    location: ILocation;
    employeeId: number | null;
    startDate?: string | Date;
    endDate?: string | Date;
    customerId?: number | null;
}

const NewAppointmentModal = ({ onClose, location, employeeId, startDate, endDate, customerId }: NewAppointmentModalProps) => {
    const { isMobile } = useEntityDrawerSpacing();
    const [depositRequest, setDepositRequest] = useState(false);
    const [appStartDate, setAppStartDate] = useState(startDate);
    const { employees: allEmployees } = useEmployeeOptions('true');
    const { data: customerData, isLoading: customerLoading } = customerAPI.useGetCustomerQuery(customerId ? String(customerId) : skipToken);
    const { appointmentDetailsFormId, appointmentDetailsSaveButtonDisabled } = useAppSelector((state) => state.calendar);
    const { calculateDuration } = useAppointmentFunctions();

    const { start, end } = useMemo(
        () => ({
            start: moment(startDate).toISOString(),
            end: moment(endDate ?? startDate).toISOString()
        }),
        [startDate, endDate]
    );

    const defaultCustomer = useMemo(() => {
        if (customerId) {
            return customerData ?? null;
        }
        return null;
    }, [customerData, customerId]);

    const defaultEmployee = useMemo(() => allEmployees.find((e) => e.id === employeeId) || null, [allEmployees, employeeId]);

    const initialData = useMemo<AppointmentFormType>(
        () => ({
            status: AppointmentStatuses.Active,
            employee: defaultEmployee,
            location,
            services: [{ service: null, price: null, prepay: null, materials_amount: null, options: [] }],
            customer: defaultCustomer,
            start_at: start,
            end_at: end,
            type: AppointmentType.Appointment,
            images: [],
            payments: null,
            is_notifications_enabled: true,
            note: '',
            private_note: '',
            deposit_request: null,
            use_reminder: false,
            duration: calculateDuration(start, end)
        }),

        [defaultEmployee, location, defaultCustomer, start, end, calculateDuration]
    );

    const canRequestDeposit = useMemo(() => (appStartDate ? !isPast(new Date(appStartDate)) : false), [appStartDate]);

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="Create Appointment" onClose={onClose} />
                <EntityDrawerContent noSpacing>
                    <Box py={2}>
                        {customerLoading ? (
                            <Box
                                sx={{
                                    minWidth: '600px',
                                    maxWidth: '100%',
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        ) : (
                            <AppointmentForm
                                data={initialData}
                                callback={onClose}
                                isDepositRequested={depositRequest}
                                handleCancelDepositRequest={() => setDepositRequest(false)}
                                isNew
                                onStartDateChange={setAppStartDate}
                            />
                        )}
                    </Box>
                </EntityDrawerContent>
                <EntityDrawerActions>
                    <Button
                        color="primary"
                        variant="contained"
                        disabled={!canRequestDeposit || depositRequest || customerLoading}
                        onClick={() => setDepositRequest(true)}
                    >
                        Request Deposit
                    </Button>

                    {!isMobile ? <Box mr="auto" pr={1} /> : null}

                    <Button onClick={onClose} variant="text" className="forcedBg">
                        Close
                    </Button>
                    {appointmentDetailsFormId && (
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            disabled={appointmentDetailsSaveButtonDisabled || depositRequest || customerLoading}
                            form={appointmentDetailsFormId}
                        >
                            Save
                        </Button>
                    )}
                </EntityDrawerActions>
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default NewAppointmentModal;
