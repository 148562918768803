import { useMemo } from 'react';

// mui
import DomainOutlinedIcon from '@mui/icons-material/DomainOutlined';
import { Box, InputAdornment } from '@mui/material';

// project imports
import useAuth from '../../hooks/useAuth';
import useChangeCompany from '../../hooks/useChangeCompany';
import CreateOrganizationBtn from './CreateOrganizationBtn';
import HeaderSelect from '../../ui-component/header-select';

const OrganizationSwitcher = () => {
    const { user } = useAuth();
    const { handleChangeCompany } = useChangeCompany();
    const userCompanies = user?.companies ?? [];

    const options = useMemo(
        () =>
            userCompanies.map((company) => ({
                id: company.id,
                label: company.name
            })),
        [userCompanies]
    );

    return (
        <>
            <HeaderSelect
                id="company_id"
                name="company_id"
                value={user?.currentCompany.id}
                onChange={(e) => handleChangeCompany(Number(e.target.value))}
                label="Company"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <DomainOutlinedIcon />
                        </InputAdornment>
                    )
                }}
                options={options}
            >
                <Box>
                    <CreateOrganizationBtn />
                </Box>
            </HeaderSelect>
        </>
    );
};

export default OrganizationSwitcher;
