import { FC } from 'react';
import { IconButton, IconButtonProps, useMediaQuery, useTheme } from '@mui/material';

const CommonHeaderBtn: FC<IconButtonProps> = ({ sx, children, ...rest }) => {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const baseSx = {
        color: 'primary.900',
        ...sx,

        '& svg': {
            width: '1.5rem',
            height: '1.5rem'
        }
    };

    return (
        <IconButton sx={baseSx} {...rest} size={!matchDownMd ? 'large' : undefined}>
            {children}
        </IconButton>
    );
};

export default CommonHeaderBtn;
