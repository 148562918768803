import { Box, CircularProgress, IconButton, MenuItem, Stack, TextField } from '@mui/material';
import { ICustomer } from '../../models/ICustomer';
import { ChangeEvent, FC, useCallback, useMemo, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import couponApi from '../../services/CouponService';
import UpdatedStyleWrapper from '../updated-style-wrapper';
import CustomerRedemptionCoupon from './components/CustomerRedemptionCoupon';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import customerAPI from '../../services/CustomerService';
import { useAppDispatch } from '../../hooks/redux';
import { SnackBarTypes } from '../../store/snackbarReducer';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import NumberFormat from 'react-number-format';

type CustomerLoyaltyProgramProps = {
    customer: ICustomer;
};

const CustomerLoyaltyProgram: FC<CustomerLoyaltyProgramProps> = ({ customer }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const [amount, setAmount] = useState<number | ''>('');
    const [createCoupon, { isLoading: isCreating }] = couponApi.useAddRedemptionCouponMutation();
    const { user } = useAuth();
    const { id, loyalty_points, redemption_coupons } = customer;
    const loyaltyProgramTemplates = user?.currentCompany.settings?.loyalty_program?.coupon_templates ?? [];

    const availableCouponAmounts = useMemo(() => {
        if (loyaltyProgramTemplates) {
            return loyaltyProgramTemplates.filter((item) => item.points <= loyalty_points).sort((prev, next) => prev.amount - next.amount);
        }

        return [];
    }, [loyaltyProgramTemplates, loyalty_points]);

    const handleChangeAmount = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setAmount(e.target.value === '' ? '' : parseFloat(e.target.value));
    }, []);

    const handleAddCoupon = useCallback(() => {
        if (amount) {
            createCoupon({ customer_id: id, amount })
                .unwrap()
                .then(() => {
                    dispatch(customerAPI.util.invalidateTags(['Customer']));
                    setAmount('');
                })
                .catch((err) => {
                    showSnackbar({
                        alertSeverity: SnackBarTypes.Error,
                        message: err.data ?? err.message ?? JSON.stringify(err)
                    });
                });
        }
    }, [amount, createCoupon, id, dispatch, showSnackbar]);

    return (
        <UpdatedStyleWrapper>
            <Stack spacing={2}>
                <Box sx={{ fontWeight: 600, fontSize: '1rem' }}>Points Earned: {loyalty_points}</Box>

                <Stack spacing={2}>
                    {redemption_coupons?.map((coupon) => (
                        <CustomerRedemptionCoupon key={coupon.id} coupon={coupon} />
                    ))}

                    {availableCouponAmounts.length ? (
                        <Stack spacing={2} direction="row" alignItems="center">
                            <TextField
                                label="Coupon Amount"
                                select
                                fullWidth
                                value={amount}
                                onChange={handleChangeAmount}
                                disabled={isCreating}
                            >
                                <MenuItem value="" sx={{ display: 'none' }} />
                                {availableCouponAmounts.map((item) => (
                                    <MenuItem value={item.amount}>
                                        <Box>
                                            <NumberFormat
                                                decimalScale={2}
                                                fixedDecimalScale
                                                prefix="$"
                                                displayType="text"
                                                value={item.amount}
                                            />
                                            {` for ${item.points} pts`}
                                        </Box>
                                    </MenuItem>
                                ))}
                            </TextField>
                            <IconButton onClick={handleAddCoupon} disabled={amount === '' || isCreating} color="primary">
                                {isCreating ? <CircularProgress color="inherit" size={24} /> : <AddOutlinedIcon />}
                            </IconButton>
                        </Stack>
                    ) : null}
                </Stack>
            </Stack>
        </UpdatedStyleWrapper>
    );
};

export default CustomerLoyaltyProgram;
