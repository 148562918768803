import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { closeConfirmPopup } from '../../store/confirmPopupSlice';
import CBModal from '../cb-modal/CBModal';
import { Box, Button, Drawer, IconButton, Typography } from '@mui/material';
import StickyFormFooter from '../form/sticky-form-footer';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

const ConfirmPopup = () => {
    const {
        confirmData: { onClose, onConfirm, confirmText, cancelText, text, isConfirmDisabled, id, title, bottomPosition, hideCancelButton },
        isOpened
    } = useAppSelector((state) => state.confirmPopup);
    const dispatch = useAppDispatch();

    const closeFunc = () => {
        if (onClose) {
            onClose();
        }
        dispatch(closeConfirmPopup());
    };

    const handleConfirm = () => {
        if (onConfirm) {
            onConfirm();
        }
        dispatch(closeConfirmPopup());
    };

    if (isOpened && bottomPosition) {
        return (
            <Drawer open onClose={closeFunc} anchor="bottom" PaperProps={{ sx: { borderRadius: '20px 20px 0 0' } }}>
                <Box p={2}>
                    <Box sx={{ color: 'grey.800', textAlign: 'right' }}>
                        <IconButton onClick={closeFunc} color="inherit">
                            <ClearOutlinedIcon />
                        </IconButton>
                    </Box>
                    <Typography
                        variant="h5"
                        sx={{
                            fontWeight: 600,
                            fontSize: '1.5rem',
                            lineHeight: '1.15',
                            mb: 1
                        }}
                    >
                        {title}
                    </Typography>
                    {text ? <Box mb={1}>{text}</Box> : null}
                    <StickyFormFooter>
                        {!hideCancelButton ? (
                            <Button color="primary" variant="outlined" onClick={closeFunc} disableElevation>
                                {cancelText ?? 'Cancel'}
                            </Button>
                        ) : null}

                        <Button color="primary" variant="contained" onClick={handleConfirm} disableElevation>
                            {confirmText ?? 'Ok'}
                        </Button>
                    </StickyFormFooter>
                </Box>
            </Drawer>
        );
    }

    return isOpened ? (
        <CBModal
            id={id}
            open
            title={title}
            onClose={closeFunc}
            okButtonText={confirmText}
            onClickOk={handleConfirm}
            okButtonDisabled={isConfirmDisabled}
            closeButtonText={onConfirm ? cancelText ?? 'Cancel' : 'Ok'}
            type="notification"
        >
            {text}
        </CBModal>
    ) : null;
};

export default ConfirmPopup;
