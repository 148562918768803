import { FC, useCallback, useEffect, useMemo } from 'react';
import { ICustomer } from '../../../models/ICustomer';
import { useAppDispatch } from '../../../hooks/redux';
import { IEmployee } from '../../../models/IEmployee';
import { Box, Button, FormHelperText, Stack, TextField, InputAdornment } from '@mui/material';
import * as Yup from 'yup';
import appointmentAPI from '../../../services/AppointmentService';
import { asyncFilesDeleteAndUpload } from '../../../utils/functions/uploading-images-helpers';
import { AppointmentServiceRowType, IAppointmentInvitePayload } from '../../../models/IAppointment';
import { startSubmitting, stopSubmitting } from '../../../store/slices/SubmittingSlice';
import useShowSnackbar from '../../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../../store/snackbarReducer';
import { getMaterialsBasedServicePrice, getServiceArrayDuration } from '../../../utils/services';
import { IService } from '../../../models/IService';
import useAuth from '../../../hooks/useAuth';
import useExtendedFormik from '../../../hooks/useExtendedFormik';
import useImagesManagement from '../../../hooks/use-images-management';
import servicesSchema from '../../form/schemes/services-array-schema';
import UpdatedStyleWrapper from '../../updated-style-wrapper';
import EntityDrawerContainer from '../../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../../entity-drawer-layout/EntityDrawerHeader';
import EntityDrawerContent from '../../entity-drawer-layout/EntityDrawerContent';
import CustomerSelect from '../../appointment-form/elements/customer-select';
import ProviderSelect from '../../appointment-form/elements/ProviderSelect';
import AppointmentServicesSubform from '../../appointment-form/elements/appointment-services-subform';
import AppointmentImageGallery from '../../appointment-image-gallery';
import AppointmentNotes from '../../appointment-form/elements/AppointmentNotes';
import NotificationsSwitch from '../../appointment-form/elements/NotificationsSwitch';
import EntityDrawerActions from '../../entity-drawer-layout/EntityDrawerActions';
import SectionHeading from '../../SectionHeading';
import useEmployeeOptions from '../../../hooks/options/useEmployeeOptions';
import { useAppointmentFunctions } from '../../../hooks/appointments';

type InviteCreationDialogProps = {
    onClose: () => void;
    duration?: number | null | undefined;
    locationId: number;
    employeeId: number;
};

type CreateInviteFormType = {
    employee?: IEmployee | null;
    location_id: number;
    services: AppointmentServiceRowType[];
    customer: ICustomer | null;
    note: string | null;
    private_note: string | null;
    duration: number | null;
    is_notifications_enabled: boolean | null;
};

const InviteCreationDialog: FC<InviteCreationDialogProps> = ({ onClose, duration, locationId, employeeId }) => {
    const dispatch = useAppDispatch();
    const { user } = useAuth();
    const { showSnackbar } = useShowSnackbar();
    const { employees: allEmployees } = useEmployeeOptions('true');
    const { deletedImages, visibleImages, onAddNewImage, onDeleteImage, imagesToUpload } = useImagesManagement();
    const { calculateAppointmentServicesDuration } = useAppointmentFunctions();
    const [sendInvite] = appointmentAPI.useSendInviteToScheduleMutation();
    const isMultiServicesEnabled = !!user?.currentCompany.settings?.widget?.use_multiservices;

    const validationSchema = Yup.object().shape({
        employee: Yup.object().typeError('Provider is required').required('Provider is required'),
        location_id: Yup.number().required(),
        service: Yup.object({ id: Yup.number().positive() }).typeError('Service is required').required('Service is required'),
        duration: Yup.number().min(10).max(600).required('Duration is required'),
        customer: Yup.object({ id: Yup.number().positive() }).typeError('Customer is required').required('Customer is required'),
        note: Yup.string().trim().nullable().notRequired(),
        private_note: Yup.string().trim().nullable().notRequired(),
        is_notifications_enabled: Yup.boolean().required(),
        services: Yup.array().of(servicesSchema).min(1, 'At least one service is required').required('Services is required')
    });

    const initialValues = useMemo(
        () => ({
            employee: allEmployees.find((e) => e.id === employeeId),
            location_id: locationId,
            services: [{ service: undefined, price: null, prepay: null, materials_amount: null, options: [] }],
            duration: duration || null,
            customer: null,
            note: '',
            private_note: '',
            price: 0,
            prepay: null,
            is_notifications_enabled: true
        }),
        [allEmployees, duration, employeeId, locationId]
    );

    const onError = useCallback(
        (error: { message?: string; data: string; errors: Record<string, string | string[]> }) => {
            dispatch(stopSubmitting());
            if (error.errors) {
                Object.entries(error.errors).forEach(([key, value]) => {
                    const errKey = key.replace('service_ids', 'services');
                    const errValue = typeof value === 'string' ? value : value.join(', ');

                    setFieldError(errKey, errValue);
                });
            } else {
                showSnackbar({
                    message: error.data ?? JSON.stringify(error),
                    alertSeverity: SnackBarTypes.Error
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch, showSnackbar]
    );

    const onSubmit = useCallback(
        (data: CreateInviteFormType) => {
            const { employee, services, customer } = data;
            if (employee && services.length && customer) {
                dispatch(startSubmitting());

                asyncFilesDeleteAndUpload(imagesToUpload, deletedImages)
                    .then((res) => {
                        const payload: IAppointmentInvitePayload = {
                            employee_id: employee.id,
                            location_id: data.location_id,
                            service_ids: services
                                .filter((s) => !!s.service)
                                .map((item) => ({
                                    id: item.service?.id ?? 0,
                                    price: item.price,
                                    prepay: item.prepay,
                                    materials_amount: item.service?.use_materials ? item.materials_amount : undefined,
                                    options: item.options
                                })),
                            customer_id: customer.id,
                            note: data.note,
                            private_note: data.private_note,
                            images: res.map(({ link }) => link),
                            duration: parseInt(String(data.duration), 10),
                            is_notifications_enabled: !!data.is_notifications_enabled
                        };

                        return sendInvite(payload).unwrap();
                    })
                    .then(() => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Success,
                            message: 'Invite Successfully Sent!'
                        });
                        onClose();
                    })
                    .catch(onError)
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [dispatch, imagesToUpload, deletedImages, onError, sendInvite, showSnackbar, onClose]
    );

    const {
        handleSubmit,
        values,
        setFieldValue,
        touched,
        errors,
        setFieldTouched,
        handleBlur,
        handleChange,
        setFieldError
    } = useExtendedFormik<CreateInviteFormType>({
        initialValues,
        validationSchema,
        onSubmit,
        validateOnChange: true,
        validateOnBlur: true
    });

    const handleChangeProvider = useCallback(
        (newValue: IEmployee | null) => {
            setFieldValue('employee', newValue);
            if (newValue) {
                setFieldTouched('employee', false);
            }

            const newServices = values.services.map((serviceRow) => {
                if (serviceRow.service) {
                    return {
                        ...serviceRow,
                        price: getMaterialsBasedServicePrice(serviceRow.service, newValue?.services || [], serviceRow.materials_amount)
                    };
                }

                return serviceRow;
            });
            setFieldValue('services', newServices);
        },
        [setFieldTouched, setFieldValue, values.services]
    );

    const combinedDuration = useMemo(() => {
        const servicesArray: IService[] = [];
        const optionsDuration = calculateAppointmentServicesDuration(values.services);
        values.services.forEach(({ service }) => {
            if (service) servicesArray.push(service);
        });

        return getServiceArrayDuration(servicesArray, values.employee) + optionsDuration;
    }, [calculateAppointmentServicesDuration, values.services, values.employee]);

    const handleDrop = useCallback(
        (files: File[]) => {
            files.forEach((file) => {
                onAddNewImage(file);
            });
        },
        [onAddNewImage]
    );

    useEffect(() => {
        if (!duration) {
            setFieldValue('duration', combinedDuration);
        }
    }, [combinedDuration, duration, setFieldValue]);

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader onClose={onClose} title="Create Invite" />
                <EntityDrawerContent>
                    <Box id="newCustomerFormContainer" sx={{ height: 0, overflow: 'hidden' }} />
                    <Stack component="form" id="CreateInviteForm" onSubmit={handleSubmit} noValidate spacing={3}>
                        <Box>
                            {/* eslint-disable-next-line react/jsx-no-undef */}
                            <CustomerSelect
                                customer={values.customer}
                                handleBlur={handleBlur}
                                error={errors.customer}
                                touched={touched.customer}
                                onChange={(c) => setFieldValue('customer', c)}
                            />
                        </Box>

                        <Box>
                            <ProviderSelect
                                values={values}
                                touched={touched}
                                errors={errors}
                                handleBlur={handleBlur}
                                onChange={handleChangeProvider}
                                location_id={locationId}
                            />
                        </Box>

                        <Box>
                            <AppointmentServicesSubform
                                value={values.services}
                                setValue={setFieldValue}
                                setTouched={setFieldTouched}
                                valuePrefix="services"
                                errors={errors.services}
                                touched={touched.services}
                                employeeServices={values.employee?.services}
                                useMultiservices={isMultiServicesEnabled}
                            />
                            {typeof errors.services === 'string' && <FormHelperText error>{errors.services}</FormHelperText>}
                        </Box>

                        <Box>
                            <SectionHeading mb={0.5}>Time</SectionHeading>
                            <TextField
                                label="Duration"
                                id="duration"
                                name="duration"
                                value={values?.duration ?? ''}
                                onChange={handleChange}
                                InputProps={{
                                    endAdornment: <InputAdornment position="start">Min</InputAdornment>
                                }}
                                error={Boolean(touched?.duration && errors.duration)}
                                helperText={touched?.duration ? errors?.duration : null}
                                fullWidth
                            />
                        </Box>

                        <Box>
                            <SectionHeading mb={0.5}>Attachments</SectionHeading>
                            <AppointmentImageGallery value={visibleImages} onDrop={handleDrop} onDelete={onDeleteImage} />
                        </Box>

                        <Box>
                            <AppointmentNotes
                                formikInstance={{
                                    values,
                                    errors,
                                    touched,
                                    setFieldTouched,
                                    setFieldValue,
                                    handleBlur
                                }}
                            />
                        </Box>
                        <Box>
                            <NotificationsSwitch
                                value={!!values.is_notifications_enabled}
                                onChange={(v) => setFieldValue('is_notifications_enabled', v)}
                            />
                        </Box>
                    </Stack>
                </EntityDrawerContent>
                <EntityDrawerActions>
                    <Button variant="text" className="forcedBg" color="primary" onClick={onClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="contained" color="primary" form="CreateInviteForm" className="DialogOkBtn">
                        Invite
                    </Button>
                </EntityDrawerActions>
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default InviteCreationDialog;
