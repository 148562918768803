import { FC, PropsWithChildren } from 'react';
import { StyledEngineProvider, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../themes/v5theme';

const UpdatedStyleWrapper: FC<PropsWithChildren<unknown>> = ({ children }) => (
    <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
);

export default UpdatedStyleWrapper;
