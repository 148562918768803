import { lazy } from 'react';

import MinimalLayout from '../layout/MinimalLayout';
import Loadable from '../ui-component/Loadable';
import Head from '../utils/head';

const CustomerDashboard = Loadable(lazy(() => import('../views/customer-pages/dashboard')));

const CustomerRoutes = {
    path: '/customer-dashboard/:company_slug',
    element: <MinimalLayout />,
    children: [
        {
            path: '/customer-dashboard/:company_slug',
            element: (
                <>
                    <Head title="Customer Dashboard |" />
                    <CustomerDashboard />
                </>
            )
        }
    ]
};

export default CustomerRoutes;
