import { FC, useCallback, useMemo } from 'react';
import EntityDrawerContainer from '../entity-drawer-layout/EntityDrawerContainer';
import EntityDrawerHeader from '../entity-drawer-layout/EntityDrawerHeader';
import { DateRange } from '../../views/calendar/types';
import moment from 'moment-timezone';
import { ILocation } from '../../models/ILocation';
import UpdatedStyleWrapper from '../updated-style-wrapper';
import appointmentAPI from '../../services/AppointmentService';
import { useAppDispatch } from '../../hooks/redux';
import useShowSnackbar from '../../hooks/useShowSnackbar';
import { SnackBarTypes } from '../../store/snackbarReducer';
import { startSubmitting, stopSubmitting } from '../../store/slices/SubmittingSlice';
import RecurringEventForm, { RecurringEventFormType } from './components/RecurringEventForm';
import getDefaultDays from './utils/get-default-days';
import { RecurrentPaymentType } from '../../models/IRecurringEvent';

type CreateRecurringEventProps = {
    location: Pick<ILocation, 'id' | 'time_zone'>;
    date_range: DateRange | null;
    onClose: () => void;
    employeeId?: number | null;
};

const CreateRecurringEvent: FC<CreateRecurringEventProps> = ({ location, date_range, onClose, employeeId }) => {
    const dispatch = useAppDispatch();
    const { showSnackbar } = useShowSnackbar();
    const [create] = appointmentAPI.useCreateRecurringEventMutation();

    const onSubmit = useCallback(
        (formData: RecurringEventFormType) => {
            const { employee_id, ...rest } = formData;
            if (employee_id) {
                dispatch(startSubmitting());
                create({ ...rest, employee_id })
                    .unwrap()
                    .then(() => {
                        onClose();
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Success,
                            message: 'Event Created Successfully!'
                        });
                    })
                    .catch((err) => {
                        showSnackbar({
                            alertSeverity: SnackBarTypes.Error,
                            message: err.message || err.data || JSON.stringify(err)
                        });
                    })
                    .finally(() => {
                        dispatch(stopSubmitting());
                    });
            }
        },
        [create, dispatch, onClose, showSnackbar]
    );

    const defaults = useMemo(
        () => ({
            location_id: location.id,
            name: '',
            price: 0,
            payment_type: RecurrentPaymentType.StartOfMonth,
            start_at: date_range?.start ? moment(date_range?.start).toISOString() : '',
            entry_duration: date_range?.duration ?? 10,
            employee_id: employeeId ?? null,
            rules: {
                is_recurring: true,
                days: getDefaultDays()
            },
            customers: []
        }),
        [date_range, employeeId, location.id]
    );

    return (
        <UpdatedStyleWrapper>
            <EntityDrawerContainer>
                <EntityDrawerHeader title="New Event" onClose={onClose} />
                <RecurringEventForm onSubmit={onSubmit} defaults={defaults} />
            </EntityDrawerContainer>
        </UpdatedStyleWrapper>
    );
};

export default CreateRecurringEvent;
